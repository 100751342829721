import type { Client, OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { parse } from 'accept-language-parser';
import { userAgent, type NextRequest } from 'next/server';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { IN_APP_PARAM } from 'shared/constants/platform';
import {
  findAppropriateLocaleForUrl,
  getCountryCodeFromISOLocale,
} from 'shared/i18n/helpers';
import { MERCHANT_CODE } from 'shared/infra/cookies';
import { getChannel } from 'shared/utils/channel-link';
import { DEFAULT_LOCALE } from 'tools/locales';
import { getLast } from 'utils/querystring';

import { EXPERIMENT_USER_COOKIE_NAME } from './browser-user';

const PLATFORM = 'storefront';
const MISSING_VALUE = 'unknown';

const getAttributesFromRequest = (
  request: NextRequest,
): Record<string, string | boolean> => {
  const { search, pathname } = request.nextUrl;
  const { referrer } = queryString.parse(search);
  const isUserFromApp = search.includes(IN_APP_PARAM);
  const channel = getChannel(request.nextUrl.pathname);
  const acceptLanguage = request.headers.get('accept-language');
  const browserInfo = userAgent(request);
  const browserName = browserInfo?.browser?.name || '';
  const browserVersion = browserInfo?.browser?.version || '';
  const deviceType = browserInfo?.device?.type || 'desktop';
  const osName = browserInfo?.os?.name || '';
  const osVersion = browserInfo?.os?.version || '';
  const acceptedLanguages = parse(acceptLanguage) || [];
  const [firstLanguage] = acceptedLanguages;
  const firstLanguageCode = firstLanguage?.code;
  const firstLanguageRegion = firstLanguage?.region;
  const merchantCode = request.cookies.get(MERCHANT_CODE)?.value || null;
  const userGAId = request.cookies.get('_ga')?.value || null;
  const userLocale = findAppropriateLocaleForUrl(request.url) || DEFAULT_LOCALE;
  const shopCountry = getCountryCodeFromISOLocale(userLocale);
  const browserLanguage =
    (firstLanguageCode && firstLanguageRegion
      ? `${firstLanguageCode}-${firstLanguageRegion}`
      : firstLanguageCode) || '';
  const cookiestring = Array.from(request.cookies.getAll())
    .map(({ name, value }) => `${name}=${value}`)
    .join('&');

  return {
    pathname,
    /** @deprecated use channel instead */
    'shopExperience': channel,
    channel,
    cookiestring,
    'shop_country': shopCountry,
    'querystring': search,
    'from_app': isUserFromApp,
    'referrer_param': getLast(referrer) || null,
    'google-analytics-user-id': userGAId,
    'platform': PLATFORM,
    'user-agent': request.headers.get('user-agent'),
    'referrer-url':
      request.headers.get('referrer') ||
      request.headers.get('referer') ||
      'direct',
    'origin-ip': request.headers.get('cf-connecting-ip'),
    'browser-language': browserLanguage,
    'browser-name': browserName,
    'browser-version': browserVersion ?? MISSING_VALUE,
    'device-type': deviceType ?? MISSING_VALUE,
    'os-name': osName,
    'os-version': osVersion ?? MISSING_VALUE,
    'MerchantCode': merchantCode,
    /**
     * @deprecated Use the `MerchantCode` attribute instead.
     */
    'mid': merchantCode,
  };
};

const getUserIdFromRequest = (request: NextRequest): string =>
  request.cookies.get(EXPERIMENT_USER_COOKIE_NAME)?.value || uuidv4();

export const getUserFromRequest = (
  optimizely: Client,
  request: NextRequest,
): OptimizelyUserContext => {
  const id = getUserIdFromRequest(request);
  const attributes = getAttributesFromRequest(request);

  return optimizely.createUserContext(id, attributes);
};
