import type { Channel } from 'shared/constants/Channel';

export const isValidChannel = (
  channelName: Channel,
  validChannels: Channel[],
): boolean => validChannels.includes(channelName);

export const areChannelsDifferent = (
  urlChannel: Channel,
  clOrderChannel: string,
): boolean => urlChannel !== clOrderChannel;

const QUOTE_CHANNELS = ['quotes', 'sidekick'] as const;

export const isQuotesChannel = (
  channel: unknown,
): channel is (typeof QUOTE_CHANNELS)[number] =>
  QUOTE_CHANNELS.includes(channel as (typeof QUOTE_CHANNELS)[number]);
